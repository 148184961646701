import isSsr from '@/shared/utils/isSsr';
const MAX_LENGTH = 120;

const getTextContent = (mainBlock?: string, search?: string) => {
  if (isSsr()) {
    return '';
  }
  const div = document.createElement('div');
  // eslint-disable-next-line immutable/no-mutation
  div.innerHTML = mainBlock?.replace(/(<style[\w\W]+style>)/g, '') ?? '';
  const text = div.textContent || div.innerText || '';
  const index = text.indexOf(search ?? '');

  if (index === -1) {
    const value = text.substring(0, MAX_LENGTH * 2);
    return `${value}...`;
  }

  const value = text.substring(index - MAX_LENGTH, index + MAX_LENGTH);
  const prefix = index > MAX_LENGTH ? '...' : '';
  const postfix = text.substring(index).length > MAX_LENGTH ? '...' : '';
  return `${prefix}${value}${postfix}`;
};

export default getTextContent;
